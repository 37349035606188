<template>
    <div class="home" id="quotaReviewList">
      <div style="text-align: left" >
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
            <el-form-item label="购方名称">
              <el-input v-model="queryForm.buyersName" placeholder="请输入查询" :clearable="true"></el-input>
            </el-form-item>
            <el-date-picker value-format="yyyy-MM-dd"
                v-model="queryForm.time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          <el-form-item>
            <el-button type="primary" @click="loadTable" icon="el-icon-search">查询</el-button>
            <el-button type="primary" @click="onReset" icon="el-icon-refresh-right">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: left;margin-bottom: 20px;">
        <el-button type="primary" icon="el-icon-download" @click="download()">下载发票PDF</el-button>
        <!-- <el-button type="primary" icon="el-icon-download" @click="downloadOFD()">发票OFD下载</el-button> -->
      </div>
      <div>
          <el-table  :header-cell-style="tableHeader" :cell-style="tableCell" :data="tableData" @selection-change="handleSelectionChange" fit border style="width: 100%">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column type="index" width="50" label="" />
          <el-table-column prop="id" :show-overflow-tooltip="true" label="应收发票 ID" />
          <el-table-column prop="buyersAddressTel" :show-overflow-tooltip="true" label="购方地址电话" />
          <el-table-column prop="buyersBankAcc" :show-overflow-tooltip="true" label="购方银行帐号" />
          <el-table-column prop="buyersName" :show-overflow-tooltip="true" label="购方名称" />
          <el-table-column prop="buyersTaxCode" :show-overflow-tooltip="true" label="购方税号" />
          <!-- <el-table-column prop="itemCode" :show-overflow-tooltip="true" label="物料编码" /> -->
          <!-- <el-table-column prop="itemName" :show-overflow-tooltip="true" label="物料说明" /> -->
          <!-- <el-table-column prop="itemSpec" :show-overflow-tooltip="true" label="规格型号" /> -->
          <!-- <el-table-column prop="itemUom" :show-overflow-tooltip="true" label="计量单位" /> -->
          <el-table-column :show-overflow-tooltip="true" label="金额">
            <template slot-scope="scope">{{accDiv(scope.row.amount,100)}}</template>
          </el-table-column>
          
          <el-table-column :show-overflow-tooltip="true" label="税率">
            <template slot-scope="scope">{{accDiv(scope.row.taxRate,1000000)}}</template>
          </el-table-column>
          <el-table-column prop="status" :show-overflow-tooltip="true"  label="状态">
            <template slot-scope="scope">
              <div v-for="(item, index) in itemList" v-bind:key="index" >
                <div v-if="item.datavalue == scope.row.status">
                  {{  item.name }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="invoicePdf" :show-overflow-tooltip="true" label="发票PDF" />
          
          <el-table-column prop="createTime" :show-overflow-tooltip="true" label="创建时间" />
          <el-table-column label="操作" fixed="right" >
            <template slot-scope="scope">
              <el-button type="text" size="small" v-if="scope.row.status != 5" @click="openUploadFrom(scope.row)" >上传PDF</el-button>
            </template>
          </el-table-column>
        </el-table>
       <el-pagination
          id="page"
          background
          @size-change="sizeChange"
          @current-change="currentChange"
          @prev-click="currentChange"
          @next-click="currentChange"
          :page-sizes="[5, 10, 20]"
          :page-size="queryForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
              <!-- 外层 -->
        <el-dialog :title="title" :visible.sync="dialogFormVisible" width="50%" style="text-align: initial;" @closed="afteraddOrUpdateDialogClose">
          <!-- 内层 -->
          <el-form :model="updateForm" :label-position="'left'" ref="updateForm" :rules="updateFormRules" label-width="150" class="updateForm" >
            
            <el-form-item :label="title"  prop="isFile" >
              <el-upload
                class="upload-demo"
                ref="upload"
                :action="upload"
                :on-preview="handlePreview"
                :on-error="handleError"
                :on-remove="handleRemove"
                :on-success="handlesuccess"
                :file-list="fileList"
                :limit=1
                :data="uploadJson"
                :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                <!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button> -->
                <div slot="tip" class="el-upload__tip">请上传发票PDF文件。</div>
              </el-upload>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="submitUploadFrom">确 定</el-button>
          </div>
        </el-dialog>
    </div>
  </template>
  <script>
  
  import XLSX from "xlsx"
  
  import validateUtils from '@/util/validateUtils'
  import { re } from 'mathjs'
  import { Progress } from "element-ui"
  import { title } from "process"
  
  export default {
      data() {
        return {
          // 0为汇总
          upload:process.env.VUE_APP_BASE_URL+'/invoice/invoiceUpload',
          title:'上传发票PDF',
          type: 0,
          queryForm: {
            time:[],
            status: 2,
            flag: 2,
            buyersName:'',
            pageNo: 1,
            pageSize: 10
          },
          total:0,
          // 这个站点对象集合
          fullscreenLoading: false,
          updateDialogVisible:false,
          groupList: [],
          options: [],
          // 状态字典列表
          summaryStateList:[],
          // 以表示排列的数组
          summaryArray: null,
          // 表格对象
          tableData: [],
          // input: '',
          updateForm: {
            lineAmount:'',
            price:'',
            quantity:''
          },
          formInline: {
            user: '',
            region: ''
          },
          uploadJson:{
            json: {},
            userId: '',
            flag:1,
            sessionId:'',
            tenantId:'',
            id: '',
            type: process.env.VUE_APP_UPLOAD_TYPE,
            queue: process.env.VUE_APP_UPLOAD_SALARY_SUMMARY_QUENCE_NAME
          },
          dialogFormVisible: false,
          innerVisible: false,
          fileList: [],
          queryParams: {
          pageNo: 1,
          pageSize: 10,
          code: "",
          name: "",
        },
        fileList: [],
        dialogFormVisible: false,
        changeStateParams:{
          arInvoiceId: '',
          status: null,
          sessionId: ''
        },
        updateFormRules: {
            isFile:[
                { required: true, message: '请选择文件！', trigger: 'change' }
            ]
         },
        itemList: [],
        urlList:[],
        ofdList:[],
        diction: {
                    code: "invoiceStatus",
                },
        }
      },
      computed: {

      },
      watch: {
        queryFormGetGroupIds(newVal, oldVal){
          this.queryForm.groupId = newVal;
        }
      },
      // 实例创建完成后调用
  
      mounted: function(){
                  this.findDictionariesItemList(this.diction);
          },
      async created(){
          let flag = this;
        // 获取分組 信息
        await this.api.platformApi.getGroupTree({}).then((res) => {
          res = res.data
          if(res.code == 200){
            flag.options = res.data;
          //   flag.loading = false;
          }
        })
        this.loadTable();
      },
      methods: {
         // 分页查看薪资信息列表
          async loadTable(){
            console.log(this.queryForm);
              this.loading = true;
              let flag = this;
              //getStaffList
              await this.api.invoiceApi.invoiceWritebackList(this.queryForm).then((res) => {
                  res = res.data
                  console.log("----发票列表-----",res);
                  // flag.loading = false;
                  if(res.code == 200){
                      flag.tableData = res.data.records;
                      flag.total = res.data.total
                      console.log(flag.tableData);
                  }else{
              this.tableData =  null;
              this.total = 0;
              this.$message.error(res.msg);
            }
              })
          },
        submitUploadFrom(){
          console.log('开始上传');
          this.uploadJson.sessionId = this.$getCookie("sessionId");
          this.uploadJson.tenantId = this.queryForm.tenantID;

          this.$refs.upload.submit();
          console.log('上传成功！');
        },
        toFloat(val){
              if(this.isEntity(val)){
                  return '';
              }
              return parseFloat(val)/10000+"%";
          },
        // 分页器 size改变是触发
         //current-change
        currentChange(page){
          this.queryForm.pageNo = page;
          this.loadTable();
        },
  
        // size-change
        sizeChange(size){
          this.queryForm.pageSize = size;
          this.loadTable();
        },
        // 重置
        onReset(){
          this.queryForm.time=[];
          this.queryForm.buyersName=''
        },
        handleSelectionChange(val){
          this.urlList = val.map(item=>{
            return item.invoicePdf
          })
          this.ofdList = val.map(item=>{
            return item.invoiceOfd
          })
        },
        openUploadFrom(row){
          this.dialogFormVisible = true;
          this.uploadJson.id = row.id;
        },
        resetForm(formName) {
          this.$refs[formName].resetFields();
        },
        afteraddOrUpdateDialogClose(){
            this.resetForm('updateForm');
            this.$refs.upload.$data.uploadFiles = [];
        },
          // 删除一选择的上传文件函数
        handleRemove(file, fileList) {
          console.log('handleRemove!');
          this.updateForm.isFile = null;
          console.log(file, fileList);
        },
        // 点击文件列表中已上传的文件时的钩子
        handlePreview(file) {
          console.log('handlePreview!');
          console.log(file);
        },
        download(){
          for(let invoicePdf of this.urlList){
            let link = document.createElement('a')
            link.style.display = 'none'
            link.target = '_blank'
            link.href = invoicePdf
            link.setAttribute('download', decodeURIComponent(invoicePdf))
            document.body.appendChild(link)
            link.click();
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
            console.log('下载成功！')
          }
        },
        downloadOFD(){
          for(let invoiceOfd of this.ofdList){
            let link = document.createElement('a')
            link.style.display = 'none'
            link.target = '_blank'
            link.href = invoiceOfd
            link.setAttribute('download', decodeURIComponent(invoiceOfd))
            document.body.appendChild(link)
            link.click();
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
            console.log('下载成功！')
          }
        },
        async findDictionariesItemList(param) {
                  let response = await this.api.platformApi.findDictionariesItemList(param);
                  console.log("数据字典", response);
                  this.itemList = response.data.data;
              },
                      // 上传失败的钩子
        handleError(err, file, fileList){
          this.$message.error('上传失败！');
        },
        // 上传成功的钩子
        handlesuccess(response, file, fileList){
          this.updateForm.groupId = [];
          this.fileList = []
          this.dialogFormVisible = false;
          this.fullscreenLoading = true;
          if(response.code == 200){
          setTimeout(() => {
            this.fullscreenLoading = false;
            this.$message({
            message: '上传成功！',
            type: 'success'
          });
            this.loadTable();
          }, 2000);
          // console.log('handlesuccess',response);
        }else{
          setTimeout(() => {
            this.fullscreenLoading = false;
            this.$message({
            message: '上传失败,请按规范上传文件!',
            type: 'error'
          });
            this.loadTable();
          }, 2000);
        }
        },
        tableHeader(column) {
          return "height:36px;background-color:#fafafa;color:#333333;padding:0";
        },
        tableCell(column) {
          return "height:40px;background-color:#fafafa;color:#000000;padding:0";
        },
        //除法函数
        accDiv(arg1, arg2){
         if(arg1!=null){
          var t1 = 0, t2 = 0, r1, r2;
          try {
            t1 = arg1.toString().split(".")[1].length;
          }
          catch (e) {
          }
          try {
            t2 = arg2.toString().split(".")[1].length;
          }
          catch (e) {
          }
          r1 = Number(arg1.toString().replace(".", ""));
          r2 = Number(arg2.toString().replace(".", ""));
          return (r1 / r2) * Math.pow(10, t2 - t1);
         }
         else{
          return null
         }
        }
      },
  }
  </script>
  
  <style scoped>
    .submitMsg{
      color: #09BF88;
      font-size: 1.5rem;
      
    }
    .submitButton{
      background-color: #09BF88;
      border: none;
    }
    .submitButton:hover{
      background-color: #3ACCA0;
    }
    .repealMsg{
      color: #FFAC1D;
      font-size: 1.5rem;
    }
    .repealButton{
      background-color: #FFAC1D;
      border: none;
    }
    .repealButton:hover{
      background-color: #FFBD4A;
    }
  
    .cancellationMsg{
      color: #F78969;
      font-size: 1.5rem;
    }
    .cancellationButton{
      background-color: #F78969;
      border: none;
    }
    .cancellationButton:hover{
      background-color: #F789;
    }
  /* -------------------------------------- */
  
  #page {
    float: right;
    margin-top: 15px;
  }
  
  #quotaReviewList {
    overflow: hidden;
    background-color: white;
    padding: 15px 20px 100px;
  }
  
  .el-dialog__body {
    border-top: 1px solid rgba(228, 228, 228, 1);
    border-bottom: 1px solid rgb(228, 228, 228, 1);
    padding-left: 0;
    padding-right: 0;
  }
  
  .el-drawer.rtl {
    overflow: scroll;
  }
  
  .updateForm{
    width: 90%;
    margin: 0 auto;
  }
  .parseXlsxBottom{
    text-align: end;
    padding: 20px;
  }
  </style>
  
  
  